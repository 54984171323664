import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SettingRepository } from '../../infrastructure/repositories/setting.repository';
import { SettingSectionsListResponse } from '../../infrastructure/response/setting/setting-sections-list.response';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  readonly settingSectionsList$ = new Observable<SettingSectionsListResponse[]>();
  private readonly settingSectionsListSource$ = new BehaviorSubject<SettingSectionsListResponse[]>(null);

  constructor(private settingRepository: SettingRepository) {
    this.settingSectionsList$ = this.settingSectionsListSource$.asObservable().pipe(filter((v) => !!v));
  }

  fetchSettingSectionsList = (): Observable<SettingSectionsListResponse[]> =>  this.settingRepository.fetchSettingSectionsList().pipe(map((result) => result),
    tap((res) => this.settingSectionsListSource$.next(res)),
  );

  fetchSettingSection = (slug: string): Observable<any> => this.settingRepository.fetchSettingSection(slug);

  updateSettingSection = (payload: Record<string, any>): Observable<null> => this.settingRepository.updateSettingSection(payload);
}

import { Injectable } from '@angular/core';
import { AbstractRepository } from '../../../core/infrastructure/repositories/abstract.repository';
import { Observable } from 'rxjs';
import { SettingSectionsListResponse } from '../response/setting/setting-sections-list.response';
import { SettingSectionResponse } from '../response/setting/setting-section.response';

@Injectable({
  providedIn: 'root',
})

export class SettingRepository extends AbstractRepository {
  private readonly url = 'shared/setting';

  fetchSettingSectionsList = (): Observable<SettingSectionsListResponse[]> =>
    this.httpClient.get<SettingSectionsListResponse[]>(this.buildUrl(`${this.url}/sections`));

  fetchSettingSection = (slug: string): Observable<SettingSectionResponse[]> =>
    this.httpClient.get<SettingSectionResponse[]>(this.buildUrl(`${this.url}/section/${slug}`));

  updateSettingSection = (payload: Record<string, any>): Observable<null> =>
    this.httpClient.put<null>(this.buildUrl(`${this.url}`), payload);
}
